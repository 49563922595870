/* Añade esto a tu archivo CSS */
.responsive-form {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  gap: 16px;
}

.text-button-mobile {
  display: none;
}

@media (max-width: 600px) {
  .responsive-form {
    flex-direction: column;
    align-items: initial !important;
    width: 100%;
  }
  .btn-confirm {
    display: contents;
  }


  .text-button-mobile {
    display: block;
    /* margin-top: 16px; */
  }
}